import React from "react";


// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

import './cookies.scss';

const Cookies = (props) => {

  return (
    <Layout>
      <SEO
        title={`Cookies policy`}
      />
      <div className='page cookiesPage'>
      <h1 className="cookiesPage__heading">
        Cookies policy
      </h1>
      <div className="cookiesPage__body">
        <p>
        In order to improve user experience, this website (zdcreatech.com) uses cookies and other client-side storage technologies. By using this website, you express your consent to the use of cookies and associated technologies.
        </p>
        <p>
        The website's Cookies policy explains what cookies are, how they are used by the website, how third parties may use cookies on this webiste, as well as your choices regarding cookies.
        </p>
        <h2>
            What are cookies?
        </h2>
        <p>
            A "cookie" is a small piece of data that is stored in your web-browser. It can be saved to your browser by a web-service and retrieved from the code in order to recognize you and make services more convenient for you.
        </p>
        <p>
            Cookies can be stored for different periods of time, depending on their purpose.
        </p>
        <p>
            Apart from "cookies" themselves, other client-side storage technologies exist (e.g. browser Local and Session Storage, IndexedDB, etc.). Being a bit different at the level of implementation, they still serve the same purpose, and are usually united under a more common and less specific term "cookies".
        </p>
        <h2>
            How this website uses cookies
        </h2>
        <p>
            This website uses persistent localStorage data in order to make experience more enjoyable and convenient. (Let's agree that not-fixing the language and colormode on every visit is pretty neat?)
        </p>
        <p>
            Besides that, in order to gather statistical information and allow some other services function properly, this website uses third-party cookies.
        </p>
        <h2>
            Third-party cookies
        </h2>
        <p>
            In addition to the own cookies, this website may also use various third-parties cookies to report usage statistics.
        </p>
        <p>
            In particular, this website uses Google Analytics to analyse the use of the website. GA privacy policy is available at: <a target="blank" href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>.
        </p>
        <h2>
            Your choices regarding cookies
        </h2>
        <p>
            You are completely free to delete cookies or instruct your web-browser to delete or refuse cookies. To do so, you can use your web-browser's configurations and developer's tools (usually, <code>Ctrl + Shift + I</code>).
        </p>
        <p>
            However, please note that if you do so, some of the pages might not display properly and some of the preferences might not be stored.
        </p>
        <h2>
            In-depth on cookies and client-side storage
        </h2>
        <p>
            More information about cookies and the related technologies can be found at the following websites:
            <ul>
                <li>
                    <a target="blank" href="https://www.allaboutcookies.org/">AllAboutCookies</a>
                </li>
                <li>
                    <a target="blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies">MDN guides on cookies</a>
                </li>
                <li>
                    <a target="blank" href="https://developer.mozilla.org/en-US/docs/Web/API/Storage">MDN guides on Storage API</a>
                </li>
            </ul>
        </p>
        <hr />
        <p>
            By continuing using this website you express your full consent with this Cookies policy. 
        </p>
      </div>
      </div>
    </Layout>
  )
}

export default Cookies;